import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5c1dd9d6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "h-full w-full flex flex-row" }
const _hoisted_2 = { class: "overflow-y-auto overflow-x-hidden" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "text p-2 transition-colors md:flex-shrink-1 flex-shrink-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_arrow_right = _resolveComponent("icon-arrow-right")!
  const _component_icon_arrow_big_bottom = _resolveComponent("icon-arrow-big-bottom")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_confirm_delete = _resolveComponent("confirm-delete")!
  const _component_main_container = _resolveComponent("main-container")!

  return (_openBlock(), _createBlock(_component_main_container, {
    title: 'Einstellungen',
    id: 'settings_container'
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.menuItems)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              id: "settingsMenu",
              class: _normalizeClass(["md:relative absolute border-r-2 border-grey-lightest transition-all flex-shrink-0 bg-white z-30", _ctx.openMenu ? 'w-72' : 'w-0'])
            }, [
              _createElementVNode("div", {
                class: "absolute -right-5 h-6 w-5 rounded-r-xl bg-grey-lightest hover:bg-grey-lighter transition-all cursor-pointer flex items-center justify-center",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openMenu = !_ctx.openMenu))
              }, [
                _createVNode(_component_icon_arrow_right, {
                  class: _normalizeClass([_ctx.openMenu ? 'rotate-180' : '', "transform transition-all"]),
                  color: 'white'
                }, null, 8, ["class"])
              ]),
              _createElementVNode("div", _hoisted_2, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuItems, (menuItem) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: menuItem.route
                  }, [
                    (
                                menuItem.requiredPermission != null
                                    ? _ctx.hasPermission(menuItem.requiredPermission)
                                    : true
                            )
                      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                          _createVNode(_component_router_link, {
                            to: { name: menuItem.route }
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", {
                                class: _normalizeClass(["flex items-center justify-between pr-5 cursor-pointer bg-white hover:text-primary-light", 
                                        _ctx.$route.name == menuItem.route ||
                                            _ctx.$route.matched[1].name == menuItem.route
                                            ? 'text-primary'
                                            : ''
                                    ])
                              }, [
                                _createElementVNode("p", _hoisted_4, _toDisplayString(menuItem.name), 1),
                                (menuItem.subPages)
                                  ? (_openBlock(), _createBlock(_component_icon_arrow_big_bottom, {
                                      key: 0,
                                      color: 'grey',
                                      class: _normalizeClass(["transform transition-all flex-shrink-0", 
                                            _ctx.$route.name == menuItem.route ||
                                                _ctx.$route.matched[1].name == menuItem.route
                                                ? 'rotate-180'
                                                : ''
                                        ])
                                    }, null, 8, ["class"]))
                                  : _createCommentVNode("", true)
                              ], 2),
                              _createElementVNode("div", {
                                class: _normalizeClass(["transform transition-all w-full overflow-hidden", 
                                        (_ctx.$route.name == menuItem.route ||
                                            _ctx.$route.matched[1].name == menuItem.route) &&
                                            menuItem.subPages
                                            ? 'h-18'
                                            : 'h-0'
                                    ])
                              }, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(menuItem.subPages, (subPage) => {
                                  return (_openBlock(), _createBlock(_component_router_link, {
                                    key: subPage,
                                    to: { name: subPage.route }
                                  }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("p", {
                                        class: _normalizeClass(["text-sm ml-5 p-2 cursor-pointer transition-colors hover:text-primary-light", 
                                                _ctx.$route.name == subPage.route ? 'text-primary' : ''
                                            ])
                                      }, _toDisplayString(subPage.name), 3)
                                    ]),
                                    _: 2
                                  }, 1032, ["to"]))
                                }), 128))
                              ], 2)
                            ]),
                            _: 2
                          }, 1032, ["to"])
                        ]))
                      : _createCommentVNode("", true)
                  ]))
                }), 128))
              ])
            ], 2))
          : _createCommentVNode("", true),
        _createVNode(_component_router_view),
        _createVNode(_component_confirm_delete, {
          open: false,
          toDeleteObject: 'Status'
        })
      ])
    ]),
    _: 1
  }))
}